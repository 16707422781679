.headerArea .headerTop {
    background: #353535

  }
  .headerArea .headerTop .headerTopMainWrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding: 0px 0;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li {
    display: inline-block;
    position: relative;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:first-child {
    padding-left: 0;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:last-child {
    padding-right: 0;
  }
  @media (max-width: 1200px) {
    .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li {
      padding: 0px 10px;
      margin: 2px 0px 2px 0px;
    }
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li a {
    display: block;
    font-size: 12px !important;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0;
    padding: 8px 5px;
    margin-top: 1px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li a {
      font-size: 11px;
    }
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li a.active {
    color: #c0b596;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:hover a {
    display: block;
    font-size: 12px !important;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0;
    padding: 8px 5px;
    margin-top: 1px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:hover a {
      font-size: 11px;
    }
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:hover a.active {
    color: #c0b596;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:hover span {
    color: #c0b596;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 999;
    width: 226px;
    padding: 5px 5px;
    background: #fff;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-transform: capitalize;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu li {
    display: block;
    padding: 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu li:last-child {
    border-bottom: none;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu li a {
    font-size: 2rem;
    color: #06163a;
    display: block;
    padding: 12px 15px;
    text-transform: capitalize;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu li a:hover, .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li ul.subMenu li a.active {
    color: #c0b596;
  }
  .headerArea .headerTop .headerTopMainWrapper .mainMenuWrap li:hover ul.subMenu {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
  @media (max-width: 800px) {
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      background: #151a30;
      transition: all 0.3s;
      z-index: 99;
      padding: 20px;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper.active {
      display: block;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap {
      text-align: left;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li {
      display: block;
      padding: 0;
      margin-bottom: 15px;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li a, .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li span {
      padding: 0px;
      position: relative;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li a i, .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li span i {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li ul.subMenu {
      top: 25px;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li ul.subMenu li {
      margin-bottom: 0;
    }
    .headerArea .headerTop .headerTopMainWrapper .responsiveWrapper .mainMenuWrap li ul.subMenu li a {
      padding: 10px 0px;
    }
  }
  .headerArea .headerTop ul {
    text-align: left;
  }
  @media (max-width: 576px) {
    .headerArea .headerTop ul {
      text-align: center;
    }
  }
  .headerArea .headerTop ul li {
    display: inline-block;
    padding: 6px 4px 3px 6px;
    color: #fff;
    font-size: 15px;
    color: #e0e0e0;
  }
  .headerArea .headerTop ul li:first-child {
    position: relative;
    padding-left: 0;
  }
  .headerArea .headerTop ul li:first-child::before {
    position: absolute;
    content: "";
    right: 0;
    top: 14px;
    width: 1px;
    height: 25px;
    background: rgba(255, 255, 255, 0.07);
  }
  .headerArea .headerTop ul li:last-child {
    padding-right: 0;
  }
  .headerArea .headerTop ul li i {
    padding-right: 2px;
    color: #cbbc99;
  }
  @media (max-width: 576px) {
    .headerArea .headerTop ul.accountLoginArea {
      justify-content: center !important;
    }
  }
  .headerArea .headerTop ul.accountLoginArea li {
    padding-left: 0;
  }
  @media (max-width: 768px) {
    .headerArea .headerTop ul.accountLoginArea li {
      padding: 0;
    }
  }
  @media (max-width: 576px) {
    .headerArea .headerTop .btnStyle {
      text-align: center !important;
    }
  }
  .headerArea .headerTop .btnStyle a {
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 9px 15px;
    border: 2px solid #c0b596;
    border-radius: 47px;
    text-transform: capitalize;
  }
  @media (max-width: 1024px) {
    .headerArea .headerTop .btnStyle a {
      font-size: 12px;
      padding: 9px;
    }
  }
  .headerArea.headerAreaStyleThree .headerTop {
    background: transparent;
    padding: 0;
  }
  @media (max-width: 768px) {
    .headerArea.headerAreaStyleThree .headerTop {
      background: #151a30;
    }
  }
  .headerArea.headerAreaStyleThree .headerTop .headerTopMainWrapper {
    padding: 10px 15px;
    background: #151a30;
  }
  @media (max-width: 768px) {
    .headerArea.headerAreaStyleThree .headerTop .headerTopMainWrapper {
      padding: 10px 0;
    }
  }
  .headerArea.headerAreaStyleThree .headerTop .headerTopMainWrapper .btnStyle a {
    padding: 9px 13px;
  }
  .livemeeting a i {
    color: #c0b596;
  }
  @media (max-width: 7000px) and (min-width: 1200px) {
    .livemeeting a {
      background: transparent;
      font-size: 12px !important;
      font-weight: 700;
      color: #fff;
      padding: 5px 11px;
      border: 2px solid #c0b596;
      border-radius: 47px;
      text-transform: capitalize;
    }
  }
  @media (max-width: 7000px) and (min-width: 1200px) and (max-width: 1024px) {
    .livemeeting a {
      font-size: 12px;
      padding: 9px;
    }
  }
  @media (max-width: 1200px) and (min-width: 515px) {
    .livemeeting a {
      background: transparent;
      font-size: 10px !important;
      font-weight: 700;
      color: #fff;
      padding: 9px 15px;
      border: 2px solid #c0b596;
      border-radius: 47px;
      text-transform: capitalize;
    }
  }
  @media (max-width: 1200px) and (min-width: 515px) and (max-width: 1024px) {
    .livemeeting a {
      font-size: 12px;
      padding: 9px;
    }
  }
  @media (max-width: 515px) and (min-width: 0px) {
    .mymeeting {
      margin-top: 5px;
    }
    .my-class-number-space {
      margin-left: 20px !important;
    }
    .my-class-time {
      display: none !important;
    }
    .livemeeting a {
      background: transparent;
      font-size: 10px !important;
      font-weight: 700;
      color: #fff;
      padding: 9px 15px;
      border: 2px solid #c0b596;
      border-radius: 47px;
      text-transform: capitalize;
    }
    .meetingClass {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 515px) and (min-width: 0px) and (max-width: 1024px) {
    .livemeeting a {
      font-size: 12px;
      padding: 9px;
    }
  }
  .heading-modal {
    font-family: 'Playfair Display', serif;
  }
  .close {
    font-size: 2.5rem !important;
    font-weight: 600 !important;
  }
  .modal-header {
    border-bottom: 3px solid #D4C291 !important;
  }
  .google-captcha-additionarea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .sent-message-area {
    padding: 50px 0px 50px 0px;
  }
  .sent-message-area p i {
    font-size: 200px;
    color: #D4C291;
  }
  .sent-button-des {
    color: white;
    background-color: #D4C291;
    font-size: 20px;
    padding: 5px 50px 5px 50px;
    border-radius: 5px;
  }
  /* @media (min-width: 992px){
   .container, .container-lg, .container-md, .container-sm {
   max-width: 1410px !important;
   }
   } */
  .upload-photo-area {
    width: 100%;
    height: 140px;
    background-color:white;
    color: #D0C4AC;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D0C4AC;
  }
  .inputfile-box18 {
    position: relative;
    border: 1px solid #D0C4AC;
    background-color: #F3F0E9;
    color: #D0C4AC;
    padding: 12px;
    height: 50px;
  }
  .inputfile18 {
    display: none;
  }
  .container18 {
    display: inline-block;
    width: 100%;
  }
  .file-box18 {
    display: inline-block;
    width: 100%;
    border: 1px solid;
    padding: 6px 15px;
    box-sizing: border-box;
    height: 50px;
  }
  .file-button18 {
    background: #D0C4AC;
    color: white;
    padding: 12px;
    cursor: pointer;
    position: absolute;
    border: 1px solid;
    top: 0px;
    right: 0px;
    height: 50px;
  }
  .image-upload > input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .image-upload {
    cursor: pointer;
  }
  